"use strict";

function Iteminview($el, options) {
  this.element = $el;
  this.callback = options.callback;
  this.inview = false;
  this.initialize();
}
Iteminview.prototype.initialize = function () {
  var self = this;
  this.windowHeight = window.innerHeight;
  this.checkPosition();
  this.addEventHandlers();
};
Iteminview.prototype.addEventHandlers = function () {
  var self = this;
  window.addEventListener("scroll", function () {
    self.checkPosition();
  });
  window.addEventListener("DOMMouseScroll", function () {
    self.checkPosition();
  });
};
Iteminview.prototype.checkPosition = function () {
  var self = this;
  var posFromTop = self.element.getBoundingClientRect().top;
  var $item = self.element;
  if (posFromTop - this.windowHeight <= -50) {
    var delay = 100;
    if (window.scrollY == 0) {
      delay = 400;
    }
    setTimeout(function () {
      $item.classList.add('inview');
      self.inview = true;
      self.callback(true);
    }, delay);
  } else {
    self.inview = false;
    self.callback();
    if ($item.classList.contains('repeat')) {
      setTimeout(function () {
        $item.classList.remove('inview');
      }, 100);
    }
  }
};
/* 
    This is your main JS file (frontend). Don't rename it. You can split up your script into sepperate files inside the src/javascript/scripts/ folder.
    Vendors will be bundled and prepended before all of your own javascript. Stick your vendor js files inside the src/javascript/vendor/ folder.
    Make sure to declare all the vendors and custom scripts inside the projectConfig.js file.
*/

document.addEventListener('DOMContentLoaded', function () {
  // item in view listener
  var $inviewitems = document.getElementsByClassName("item-in-view");
  function inviewCB() {}
  ;
  if ($inviewitems.length > 0) {
    for (var i = 0; i < $inviewitems.length; i++) {
      var iv = new Iteminview($inviewitems[i], {
        row: false,
        callback: inviewCB
      });
    }
  }

  // lazy loading
  function loadImage($image) {
    if ("IntersectionObserver" in window) {
      let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            let lazyImage = entry.target;
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.classList.remove("load");
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });
      lazyImageObserver.observe($image);
    } else {
      // Possibly fall back to a more compatible method here
    }
  }
  ;
  var $loadimages = document.getElementsByClassName("load");
  if ($loadimages.length > 0) {
    for (var i = 0; i < $loadimages.length; i++) {
      loadImage($loadimages[i]);
    }
  }
});